<template>
    <div class="newsWrap">
        <Header></Header>
        <topBg></topBg>
        <div class="newContent">
            <div class="leftView">
                <div class="Search">
                    <!-- <input type="text" v-model="inputSearch" :placeholder="$t('oa.searchNews')" /> -->
                    <el-input class="input" v-model="inputSearch" :placeholder="$t('oa.searchNews')"></el-input>
                    <button @click="getNewsList">{{$t('oa.searchNews')}}</button>
                </div>
                <div class="newsList">
                    <div class="newsItem" v-for="(element,index) in newsList.tableData" :key="index" @click="getDetails(element,index)">
                        <div class="leftImg">
                            <img :src="element.urlPath">
                        </div>
                        <div class="right">
                            <div class="date">{{element.newDate}}</div>
                            <div class="title">{{element.subject}}</div>
                        </div>
                    </div>
                </div>
                <noData :isShow="newsList.tatol == 0 || newsList.tatol == null" :text="$t(`oa.noData`)"></noData>
                <div class="pagination" v-show="newsList.tatol!=-1 && newsList.tatol">
                    <el-pagination layout="total, prev, pager, next" @current-change="changePage" :page-size="newsList.limit" :total="newsList.tatol" />
                </div>
            </div>
            <div class="rightView">
                <div class="nwesTitle">
                    <span>{{newsContent.subject}}</span>
                </div>
                <div class="newsContent" v-html="newsContent.content"></div>
                <div class="newsTips">
                    <p v-if="this.newsList.tableData[newsList.index - 1]" @click="newsPrev">{{$t('oa.prev')}}：{{this.newsList.tableData[newsList.index - 1].subject}}</p>
                    <p v-if="this.newsList.tableData[newsList.index + 1]" @click="newsNext">{{$t('oa.next')}}：{{this.newsList.tableData[newsList.index + 1].subject}}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import url from "@/api/url.js";
import noData from "@/components/noData.vue";
import Header from "@/components/header.vue";
import topBg from "@/components/topBg.vue";
export default {
    components: {
        noData,
        Header,
        topBg,
    },
    data() {
        return {
            inputSearch: "",
            leftShow: false,
            newsList: {
                tableData: [],
                limit: 6,
                page: 1,
                loading: false,
                tatol: -1,
                index: 0,
            },
            newsID: "",
            newsContent: {},
            id:''
        };
    },
    created() {
        this.id = this.$route.query.id
        this.getNewsList();
    },
    mounted(){
        window.scrollTo(0,0);
    },
    methods: {
        getDetails(item, index,id) {
            this.newsList.index = index;
            this.newsID = id?id:item.uuid;
            this.$axios.get(url.newsDetails + `${this.newsID}`).then((res) => {
                this.newsContent = res.new;
            });
        },
        changePage(num) {
            this.newsList.page = num;
            this.getNewsList();
        },
        chagneVal(e) {
            if (!e) {
                this.getNewsList();
            }
        },
        getNewsList() {
            if (this.newsList.loading) return;
            this.newsList.loading = true;
            this.$axios
                .post(url.newsList, {
                    page: this.newsList.page,
                    limit: this.newsList.limit,
                    subject: this.inputSearch,
                })
                .then((res) => {
                    this.newsList.tableData = res.page.list;
                    this.newsList.tatol = res.page.totalCount;
                    res.page.list.length &&
                        this.getDetails(res.page.list[0], 0,this.id);
                })
                .finally(() => {
                    this.newsList.loading = false;
                });
        },
        newsPrev() {
            if (this.newsList.index > 0) {
                this.newsList.index = this.newsList.index - 1;
                this.getDetails(
                    this.newsList.tableData[this.newsList.index],
                    this.newsList.index
                );
            }
        },
        newsNext() {
            if (this.newsList.index < this.newsList.limit) {
                this.newsList.index = this.newsList.index + 1;
                this.getDetails(
                    this.newsList.tableData[this.newsList.index],
                    this.newsList.index
                );
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.newsWrap {
    background-color: #fff;

    /deep/.el-pager li.active {
        color: #CE995F;
    }
    .newContent {
        margin: 0 auto;
        width: 1320px;
        display: flex;
        padding: 40px 0;
        .leftView {
            width: 410px;
            // min-height: 1100px;
            // background-color: #ffffff;
            .pagination {
                display: flex;
                justify-content: center;
                padding:10px 30px;
                border-radius: 4px;
                background-color: #fff;
                box-shadow: 0 0 20px rgba(100,100,100,.1);
            }
            .Search {
                width: 410px;
                height: 75px;
                background-color: #fff;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 15px;
                box-shadow: 0 0 20px rgba(100, 100, 100, 0.1);
                .input {
                    // width: 370px;
                    flex: 1;
                    margin-right: 20px;
                    height: 40px;
                    border: none;
                    box-sizing: border-box;
                }
                /deep/.el-input__inner {
                    border-radius: 30px;
                }
                button {
                    width: 80px;
                    height: 40px;
                    border: none;
                    background: linear-gradient(90deg, #305A9E 0%, #0E6FA7 100%);
                    color: #fff;
                    cursor: pointer;
                    border-radius: 20px;
                }
            }

            .newsList {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px 0;
                .newsItem {
                    width: 100%;
                    height: 125px;
                    box-shadow: 0 0 20px rgba(100, 100, 100, 0.1);
                    // border: 1px solid #eeeeee;
                    border-radius: 8px;
                    padding: 15px;
                    margin-bottom: 20px;
                    cursor: pointer;
                    display: flex;
                    .leftImg {
                        width: 125px;
                        height: 95px;
                        border-radius: 6px;
                        position: relative;
                        overflow: hidden;
                        margin-right: 20px;
                        img {
                            position: absolute;
                            height: 100%;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%) scale(1);
                            transition: .3s ease-in-out;
                        }
                    }
                    .right {
                        flex: 1;
                        .title {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            overflow: hidden;
                            font-weight: bold;
                            color: #333;
                            line-height: 20px;
                        }
                        .date {
                            color: #CE995F;
                            // font-weight: bold;
                            padding: 5px 0;
                        }
                    }
                }
                .newsItem:hover{
                    .leftImg{
                        img{
                            transform: translate(-50%, -50%) scale(1.1);
                        }
                    }
                }
            }
        }
        .rightView {
            flex: 1;
            background-color: #fff;
            margin-left: 35px;
            padding: 30px;
            box-shadow: 0 0 20px rgba(100, 100, 100, 0.1);
            padding-bottom: 0;
            .nwesTitle {
                border-bottom: 1px solid #eaeaea;
                width: 100%;
                min-height: 56px;
                text-align: center;
                padding-bottom: 20px;
                span {
                    color: #000;
                    font-size: 20px;
                    line-height: 35px;
                    display: inline-block;
                    text-align: left;
                    font-weight: bold;
                }
            }
            .newsContent {
                padding: 15px 0;
                line-height: 28px;
                min-height: 830px;
            }
            .newsTips {
                padding: 30px 0;
                min-height: 60px;
                border-top: 1px solid #eaeaea;
                p {
                    color: #77777b;
                    line-height: 30px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>